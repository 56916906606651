<template>
  <div class="main d-flex justify-content-between w-100">
    <div class="position-contents">
      <b-row>
        <b-col
          class="business-name d-inline-block"
          :class="{ notification: notification }"
          @click="localOnClick()"
        >
          <span>{{ position.getProductType.name }}</span>
        </b-col>
        <b-col cols="5" class="created-at d-inline-block d-sm-none text-right">
          <img :src="require('@/assets/icons/position-pending.svg')">
          {{ position.getSimpleCreatedAt }}
        </b-col>
      </b-row>
      <div class="position-data d-flex flex-nowrap">
        <div>
          <img
            class="business-avatar"
            :src="getAvatarUrl(position.getBusinessAvatar)"
            :alt="'avatar de' + position.getBusinessName"
          >
        </div>
        <div>
          <div class="position-info d-flex flex-column justify-content-between h-100">
            <div class="position-name">{{ position.getTitle }}</div>
            <div class="position-location">
              <img :src="require('@/assets/icons/icon-map-pin.svg')">
              {{ position.getShortLocation }}
            </div>
            <div class="position-date">
              <img width="16" :src="require('@/assets/icons/booking.svg')">
              Fechas:
              {{ position.getEventDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="side d-flex justify-content-between flex-column text-right">
      <div class="price">
        {{ position.getQuantity }}
      </div>
      <div class="position-text">
        <b-button
          class="float-right see-more my-3"
          @click="localOnClick()"
          variant="secondary">
          Ver
        </b-button>
      </div>
      <div class="created-at d-none d-sm-block">
        <img :src="require('@/assets/icons/position-pending.svg')">
        {{ position.getSimpleCreatedAt }}
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'HardwareBookingPositionSearchItem',
  props: {
    position: {
      type: Object,
      required: true
    },
    notification: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_IMAGE_API_URL,
      popover: false
    }
  },
  methods: {
    localOnClick () {
      this.$emit('onClick', this.position.getId)
    },
    getAvatarUrl (avatar) {
      if (!avatar) {
        return '/img/default-user-avatar.svg'
      }

      return this.baseUrl + avatar.path
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../style/colors";
@import "../../style/responsive";

.main {
  margin: 0;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid @color_dark_purple;
  .created-at {
    font-size: 0.75rem;
    img {
      width: 14px;
    }
  }
  .position-contents {
    .business-name {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1.2rem;
      &.notification {
        span {
          position: relative;
          &::before {
            content: " ";
            position: absolute;
            border-radius: 50%;
            background-color: @color_radiant_pink;
            width: 6px;
            height: 6px;
            top: 4px;
            right: -5px;
          }
        }
      }
    }
    .business-avatar {
      width: 80px;
    }
    .position-name {
      font-size: 1rem;
      font-weight: 500;
      color: @color_radiant_pink;
    }
    .position-location {
      font-size: 0.75rem;
      img {
        width: 16px;
      }
    }
    .position-date {
      font-size: 0.75rem;
    }
    .position-jobandtalent {
      font-size: 0.75rem;
      &__text {
        vertical-align: sub;
      }
      &__icon {
        margin-left: 2px;
        color: #c4c4c4;
        font-size: 11px;
      }
    }

    .position-info {
      padding-left: 1.5rem;
      div {
        margin-bottom: 5px;
      }
      div:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .side {
    .price {
      font-size: 1.5rem;
      font-weight: 700;
      margin-left: 15px;
      @media (max-width: @break_xs) {
        text-align: left;
        margin-left: 104px;
      }
    }
    .see-more {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      @media (max-width: @break_xs) {
        width: 100%;
      }
    }

    @media (max-width: @break_xs) {
      margin-top: 1rem;
    }
  }

  @media (max-width: @break_xs) {
    flex-direction: column;
  }
}

</style>
