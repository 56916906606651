<template>
  <b-popover :target="target" :show.sync="show">
    <p>
      La empresa que oferta esta posición va a realizar la contratación a través de WORKOUT ETT.
    </p>
    <p>
      ¿Qué quiere decir?
    </p>
    <p>
      WORKOUT ETT será la responsable de tu contratación, pago de nóminas y seguros sociales, formación en Prevención de riesgos laborales y gestión de documentación que conlleva sin coste adicional.
    </p>
    <h4>VENTAJAS WORKOUT ETT.</h4>

    <ul>
      <li>
        Contarás con un <strong>asesor laboral</strong> que te informará del <strong>modelo de contratación acordado</strong>, del salario y gestionará el chequeo de los horarios, pluses y gestión derivada de tu contratación.
      </li>
      <li>
        Transparencia de costes (salario, retenciones y costes sociales)
      </li>
      <li>
        <strong>Formación en Prevención de Riesgos Laborales</strong> gratuita y <strong>gestión de EPí´s y Reconocimientos médicos</strong> en caso de ser necesarios.
      </li>
      <li>
        Seguridad ante <strong>el abono de tu nómina</strong> y <strong>seguros sociales</strong>.
      </li>
      <li>
        Cobertura ante cualquier accidente de trabajo o itinere a través de <strong>MUTUA UNIVERSAL</strong>.
      </li>
      <li>
        <strong>Gestión digital de los procesos</strong>.
      </li>
      <li>
        <strong>Envío de Certificados de Empresa al INEM</strong>.
      </li>
    </ul>
  </b-popover>
</template>
<script>

export default {
  name: 'WorkoutDisplayTechnicianPopover',
  props: {
    target: String,
    popover: Boolean
  },
  data () {
    return {
      show: false
    }
  }
}

</script>
<style scoped lang="less">
  .popover {
    max-width: 400px;
  }
</style>
